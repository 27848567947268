.login-main {
    background-color: #f6f7fc;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-title {
    font-size: 20px;
    padding-bottom: 20px;
}

.login-card {
    width: 600px;
    height: 500px;
    padding-left: 50px;
    padding-right: 50px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.login-logo {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.login-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.login-version {
    padding-top: 20px;
}

@media (max-width: 768px) {
    .login-card {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        /* padding-left: 10px; */
        /* padding-right: 10px; */
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    }
}

/* Styles for screens with a minimum width of 1200px (e.g., desktop) */
@media (min-width: 1200px) {
    .some-element {
        font-size: 18px;
    }
}