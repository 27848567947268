/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Lao&display=swap"); */
/* src/styles.css */
/* @import '@ckeditor/ckeditor5-build-classic/build/translations/ckeditor.css'; */

:root {
  --color-212529: #212529;
  --color-ffd333: #ffd333;
  --color-47ad24: #47ad24;
  --color-e52e2e: #e52e2e;
  --color-828599: #828599;
  --color-3d464d: #ed464d;
  --color-c3d4da: #ced4da;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Lao", sans-serif;
}

.ant-menu-item {
  /* background-color: #00842e !important; */
  color: white;
}

.ant-menu-item-selected {
  background-color: #00842e !important;
  color: white;
}

.Animation-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
}

.custom-active-menu-item {
  background-color: red;
}

@media (max-width: 768px) {
  .Animation-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
  }
}

.pagination .page-item.active .page-link {
  background-color: #00842e;
  border-color: #00842e;
  color: #FFFFFF;
}


.ck.ck-editor__editable .ck-widget .ck-widget__type-around__button {
  display: none;
}