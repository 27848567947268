.header-home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #FFFFFF; */
}

.red {
    color: red;
}

.header-home-button {
    display: flex;
    align-items: center;
}

.header-home-search {
    display: flex;
    align-items: center;
}

.content-title-home {
    font-size: 14px;
    color: #334155;
    padding-top: 30px;
    padding-bottom: 20px;
}

.content-home {
    /* padding-top: 20px; */
    overflow-x: auto;
    background: #FFFFFF;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

@media (max-width: 768px) {
    /* .main-content-home {
        padding-right: 20px;
    } */

    .main-home {
        background-color: #FFFFFF;
        width: 100%;
    }

    .header-home {
        display: flex;
        flex-direction: column;
    }

    .header-home-search {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
    }

    .header-home-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
    }

    .content-title-home {
        margin-top: 0px;
        padding-left: 20px;
        font-weight: 600;
        font-size: 18px;
    }

    .content-home {
        padding-left: 20px;
        padding-right: 20px;
    }


}


/* Styles for screens with a minimum width of 1200px (e.g., desktop) */
@media (min-width: 1200px) {
    .some-element {
        font-size: 18px;
    }
}