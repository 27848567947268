.management-main {
    padding-bottom: 100px;
}

.management-header {
    font-size: 16px;
    padding-bottom: 10px;
}

.management-content {
    display: flex;
    justify-content: space-around;
    /* width: 100%; */
    color: #374151;
}

.mamagement-card {
    width: 100%;
}

.management-card-header {
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--default-white, #FFF);
    /* /shadow/base */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.management-card-header-icons {
    position: absolute;
    width: 20%;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* background: red; */
}

.management-card-content {
    width: 100%;
    margin-top: 20px;
    position: relative;
    height: auto;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    background: var(--default-white, #FFF);
    /* /shadow/base */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.management-card-content-kebap-menu {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.management-card-content-add {
    width: 100%;
    margin-top: 20px;
    height: auto;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    background: var(--default-white, #FFF);
    cursor: pointer;
    transition: transform 0.3s ease;
    /* /shadow/base */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.management-card-content-add:hover {
    background-color: #00842e;
    color: white;
    transform: scale(1.03);
    font-weight: 400;
}

.management-icons-add {
    color: #374151;
}

.management-card-content-add:hover .management-icons-add {
    color: #FFFFFF;
}




@media (max-width: 768px) {
    .management-main {
        width: 100%;
        background-color: #FFFFFF;
    }

    .management-header {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        font-size: 20px;
    }

    .management-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .mamagement-card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .management-card-header {
        max-width: 100%;
        min-width: 100%;
    }

    .management-card-content {
        max-width: 250px;
        min-width: 250px;
        
    }

    .management-card-content-add {
        min-width: 150px;
        max-width: 150px;
        max-width: 30px;
        min-height: 30px;
    }
}

.quill-editor {
    border: none !important;
}

.no-border .ck.ck-editor {
    border: none !important;
  }
  

/* Styles for screens with a minimum width of 1200px (e.g., desktop) */
@media (min-width: 1200px) {
    .some-element {
        font-size: 18px;
    }
}